import * as React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import format from 'date-fns/format'

import Card from "@components/common/Card"

const Main = props => {
  return (
    <Container>
      <Body>
        {props.data.map((elem, index) => {
          const node = elem.node;
          return (
            <Card
              src={node.hero_image.url}
              title={node.title}
              createdAt={format(new Date(node.publishedAt), 'yyyy.MM.dd')}
              onClick={() => navigate(`/blog/${node.blogId}/`)}
              titleComponent='h3'
              key={`card${index + 1}`}
            />
          )
        })}
      </Body>
    </Container>
  )
}

const Container = styled.div`
  padding: 0px 24px;
`;

const Body = styled.div`
  margin-top: 32px;
  margin-bottom: 40px;
`;

export default Main
