import * as React from "react";
import { graphql } from "gatsby";

import Layout from "@components/common/Layout"
import Seo from "@components/common/SEO"
import Top from "@components/index/Top"
import Main from "@components/index/Main"

const Blogs = ({ data }) => {
  return (
    <Layout>
      <Seo
        title={'マッチングアプリ アナライザー'}
        description={'元マッチングアプリ開発者が徹底解説'}
      />
      <Top />
      <Main data={data.allMicrocmsBlog.edges} />
    </Layout>
  )
}

export const query = graphql`
  {
    allMicrocmsBlog(
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          blogId
          title
          hero_image {
            url
          }
          summary
          meta_description
          content
          category
          publishedAt
          revisedAt
          related_blogs {
            id
            title
            hero_image {
              url
            }
            category
            publishedAt
            revisedAt
          }
        }
      }
    }
  }
`
export default Blogs
