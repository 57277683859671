import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const Top = () => {
  return (
    <Container>
      <Body>
        <StaticImage
          src="../../images/column-list_top.png"
          alt="マッチングアプリ アナライザーとは"
        />
        <Section>
          <Title>記事一覧</Title>
          <Text>
            元マッチングアプリ開発者による<br />
            徹底解説コラムをご覧いただけます。
          </Text>
        </Section>
      </Body>
      <Dummy />
    </Container>
  )
}

const Container = styled.div`
`;

const Body = styled.div`
  position: relative;
`;

const Section = styled.div`
  width: 89%;
  background: #DBB34D;
  position: absolute;
  bottom: -30%;
  padding: 36px 24px;
`;

const Dummy = styled.div`
  padding-bottom: 18.08%;
  margin-bottom: 54px;
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 22px;
  line-height: 1.5;
  color: #FFFFFF;
  margin-bottom: 7px;
`;

const Text = styled.span`
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.08em;
  color: #FFFFFF;
`;

export default Top
